.billing-calculation-container {
  .billing-select-month {
    border: none;
    padding: 5px;
    background-color: #8950fc;
    color: white;
    font-weight: 700;
    border-radius: 5px;
    text-align: center;
    margin-right: 5px;
  }

  .calculationinput {
    margin-bottom: 24px;
    width: 170px;
    width: 100%;
    height: 44px;
    background: #f3f6f9;
    border-radius: 9px;
    border: none;
    padding-left: 10px;
    padding-right: 10px;
  }

  .calculationinput:focus {
    outline: none;
  }

  .total-amount-div {
    margin-left: 30px;
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .savebtn {
    width: 100%;
    height: 42px;
    margin-top: 9px;
    background-color: #8950fc;
    border: none;
    border-radius: 6px;
    color: white;
    font-family: Poppins;
    font-size: 13px;
    font-weight: 600;
    text-align: center;
  }

  .savebtn2 {
    width: 100%;
    height: 42px;
    margin-top: 200px;
    background-color: #8950fc;
    border: none;
    border-radius: 6px;
    color: white;
    font-family: Poppins;
    font-size: 13px;
    font-weight: 600;
    text-align: center;
  }

  .total-amount {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 800;
    color: #464e5f;
    margin-left: 70px;
  }

  .vertical-border {
    margin-top: 35;
    border-left: 2px solid #eeeff3;
    margin-left: 52px;
    height: 411px;
  }

  .horizontal-border {
    max-width: 172px;
    margin-left: 60px;
    margin-right: 80px;
  }

  .total-amount-text {
    font-family: Poppins;
    font-size: 17px;
    font-weight: 600;
    color: #b5b5c3;
    text-transform: uppercase;
    margin-left: 70px;
  }

  .cost-gj {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    color: #80808f;
    line-height: 21px;
    margin-right: 30px;
  }

  .cost-num {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    color: #464e5f;
    line-height: 21px;
    margin-right: 30px;
  }

  .cost-div {
    margin-top: 5%;
    display: flex;
    flex-direction: column;
    text-align: right;
  }

  .billingorange {
    color: #ffa800;
    font-weight: 600 !important;
    font-size: 20px;
    font-family: Poppins;
  }

  .billingblue {
    color: #3699ff;
    font-weight: 600 !important;
    font-size: 20px;
    font-family: Poppins;
  }

  @media (max-width: 580px) {
    .calculationinput {
      max-width: 200px !important;
    }

    .total-amount-div {
      margin-left: 5 !important;
    }

    .total-amount {
      margin-left: 40px;
      font-size: 16px;
    }

    .savebtn2 {
      margin-top: 150px;
    }

    .billing-select-month {
      margin-left: 20px;
    }

    .cost-div {
      margin-top: 0 !important;
    }

    .cost-gj {
      margin-top: 0 !important;
      margin-right: 0;
    }

    .cost-num {
      margin-right: 0;
    }

    .horizontal-border {
      display: none;
    }

    .vertical-border {
      margin-left: 0 !important;
      border: none !important;
    }
  }

  @media (max-width: 991px) and (min-width: 580px) {
    .calculationinput {
      max-width: 140px !important;
    }

    .vertical-border {
      margin-left: 0px !important;
    }

    .date {
      margin-top: 0 !important;
    }

    .total-amount-text {
      font-size: 12px !important;
    }
  }

  @media (max-width: 1200px) and (min-width: 990px) {
    .calculationinput {
      max-width: 150px !important;
    }

    .total-amount-div {
      margin-left: 5 !important;
    }

    .vertical-border {
      margin-left: 0 !important;
    }
  }

  @media (min-width: 1401px) {
    .vertical-border {
      margin-left: 100px !important;
    }
  }

  @media (max-width: 522px) {
    .selector-div {
      margin-top: 30px;
    }
  }
}
