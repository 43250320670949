.invoicesContainer {
  .invoices-search {
    flex: 1;
    width: 100%;
    height: 35px;
    background-color: #f3f6f9;
    min-width: 200px;
  }

  .invoices-table {
    overflow-x: auto;
    min-width: 1000px;
  }

  .invoice-input {
    height: 44px;
    min-width: 110px;
    width: 100%;
    border-radius: 9px;
    background: #f3f6f9;
    border: none;
    text-align: center;
  }

  .invoice-th p {
    /*margin-top: 0;*/
    margin-bottom: 0;
    line-height: 11.7px;
  }

  .invoice-input:focus {
    outline-color: #9b51e0;
    border-radius: 9px;
    /* outline-width: medium; */
  }

  .tenant-download-invoice {
    width: 200px;
  }
}

@media (max-width: 588px) {
  .search-month-send {
    flex: 1;
    margin-top: 16px;
  }
}

@media (max-width: 550px) {
  .search-month-send {
    margin-left: 0 !important;
  }
}
