//
// Error 6
//

// Initialization of global variables, mixins and functions
@import '../../init';

.error.error-6 {
  .error-title {
    font-size: 5rem !important;
  }
}

@include media-breakpoint-up(md) {
  .error.error-6 {
    .error-title {
      font-size: 10rem !important;
    }
  }
}
