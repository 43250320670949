.login-container {
  max-width: 380px;
  width: 100%;

  .auth-input {
    height: 50px;
    width: 100%;
    margin: auto;
    background-color: #eef1f5 !important;
    border: none;
    font-size: 20px;
    border-radius: 12px;
    padding-left: 12px;
    padding-right: 12px;
    text-align: left;
  }
  .show-password {
    margin-top: 30px;
  }

  .login-form {
    margin-top: 15%;
  }
  .MuiFilledInput-input {
    padding: 0 !important;
    text-align: left !important;
    font-family: Poppins;
    box-sizing: inherit;
    padding-left: 10px !important;
  }

  .graybutton {
    height: 48px;
    width: 100%;
    border-radius: 6px;
    background: #464e5f;
    color: white;
    margin-top: 50px;
    font-weight: 600;
    border: none;
    font-size: 15px;
  }

  .forgot {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    color: #3699ff;
    float: right;
    margin-right: 25px;
  }

  .recovery-back-button {
    height: 48px;
    width: 182px;
    border-radius: 6px;
    padding: 16px, 80px, 16px, 80px;
    background-color: rgba(63, 66, 84, 0.04);
    color: rgba(63, 66, 84, 0.64);
    border: none;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    margin-right: 8px;
  }
}
