/*auth pages */
.graybutton:hover {
  color: white;
}

.organization-title {
  font-family: Poppins;
  font-size: 17px;
  font-weight: 600;
  text-align: center;
}

.auth-input:focus {
  outline: none;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px #eef1f5 inset;
}

.auth-2ndtitle {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  color: #2f3044;
}

.authtitles {
  font-family: Poppins;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 42px;
  letter-spacing: -0.02em;
  text-align: center;
  color: #2f3044;
}

.recovery-back-button {
  height: 48px;
  width: 182px;
  border-radius: 6px;
  padding: 16px, 80px, 16px, 80px;
  background-color: rgba(63, 66, 84, 0.04);
  color: rgba(63, 66, 84, 0.64);
  border: none;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  margin-right: 8px;
}

.recovery-step1-div {
  margin-top: 50px;
}

.recovery-next1-button {
  margin-top: 50px;
  background-color: #464e5f;
  color: white;
  width: 466px;
  height: 56px;
  font-weight: 700;
  border-radius: 6px;
  border: none;
  font-size: 16px;
}

.recovery-input {
  height: 50px;
  width: 380px;
  background-color: #eef1f5 !important;
  border-radius: 12px;
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
}

.recovery-next2-button {
  height: 48px;
  width: 182px;
  border-radius: 6px;
  padding: 16px, 80px, 16px, 80px;
  background-color: #464e5f;
  color: white;
  border: none;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  margin-left: 8px;
}

.details-nums {
  width: 90px;
  height: 34px;
  margin-top: 5px;
  background-color: rgba(128, 128, 143, 0.08);
  font-weight: 500;
  padding: 7px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}

@media (max-width: 1400px) and (min-width: 576px) {
  .details-nums {
    width: 100%;
  }
}

​ .modalbtns {
  background-color: #8950fc;
  color: white;
}

@media (max-width: 648px) {
  .expenditure-history-title {
    /* text-align: center; */
    /* float:right; */
    display: flex;
    justify-content: center;
  }
}

@media (max-width: 767px) {
  .tenant-info-det {
    margin-left: 200px;
  }

  .select-month {
    width: 82px;
  }
}

@media (max-width: 1400px) and (min-width: 1200px) {
  .tenant-info-det {
    margin-left: 70px !important;
  }
}

@media (max-width: 495px) {
  .tenant-info-det {
    margin-left: 0;
  }
}

​ .modalbtns {
  background-color: #8950fc;
  color: white;
}

@media (max-width: 767px) and (min-width: 640px) {
  .media-margin {
    margin-left: 340px;
  }
}

/* Edit Tenant Start */
.edittenantmodalcontainer {
  border-radius: 12px;
  width: 320px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
}

/* Edit Tenant end */

.editusermodalcontainer {
  border-radius: 12px;
  width: 320px;
  display: flex;
  padding: 30px;
  flex-direction: column;
  align-items: center;
  background-color: white;
}

/* Add Tenant Start */
.tenantcontainer {
  border-radius: 12px;
  display: flex;
  justify-content: center;
  width: 526px;
  margin-top: 104px;
  max-height: 508px;
}

.add-tenant-btn {
  background: #8950fc;
  color: white;
  width: 155px;
  height: 40px;
  border: none;
  border-radius: 6px;
  padding: 1px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
}

.tariff-form-container {
  border-radius: 12px;
  display: flex;
  justify-content: center;
  width: 526px;
  margin-top: 80px;
  max-height: 550px;
}

.forgot-container {
  display: flex;
  justify-content: center;
  /* margin-top: 3%; */
  box-shadow: none;
  box-shadow: none !important;
}

.forgot-form {
  display: flex;
  justify-content: center;
  background-color: #fff;
  border: none;
}

.otp-container {
  display: flex;
  justify-content: space-around;
  width: 400px;
}

.try-again-otp {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 50px;
}

.did-not-received-p {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
}

.enter-otp-p {
  font-family: HelveticaNeueLTGEOW82;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
}

.try-again-p {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  text-align: right;
  color: #3699ff;
  text-decoration-line: underline;
  cursor: pointer;
}

.otp-code,
.MuiInputBase-input {
  text-align: center;
  font-family: Poppins;
  box-sizing: inherit;
  /* font-weight: 600 !important; */
  font-size: 18px !important;
  border: none;
}

.otp-code {
  width: 45px;
  height: 45px;
  background: #eef1f5;
  border-radius: 12px;
  display: flex;
  justify-content: center;
}

.otp-div {
  display: flex;
  justify-content: center;
}

.tenantform {
  border-radius: 12px;
  display: flex;
  justify-content: center;
}

.enterdetailsmodal {
  font-family: Poppins;
  font-style: normal;
  display: flex;
  justify-content: center;
  font-weight: 500;
  font-size: 14px;
  margin: 20px;
  color: #8950fc;
}

.modalinput {
  border: 9px;
  width: 260px;
  background-color: #f3f6f9;
  border-radius: 9px;
}

.tenantwasadded {
  margin-top: 18px;
  margin-bottom: 50px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  text-align: center;
}

.blur {
  filter: blur(8px);
}

/* Add Tenant End */

.orange {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  text-align: left;
  color: #ffa800;
  background-color: white !important;
}

.exp-details-div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.alert-text {
  font-weight: bolder;
  font-size: 18px;
  background: -webkit-linear-gradient(#e73a45 7.2%, #44368a 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.blue {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  text-align: left;
  color: #3699ff;
  background-color: white !important;
}

.chart {
  background: linear-gradient(139.98deg, #e73a45 1.3%, #44368a 77.85%);
  border-radius: 12px;
}

.closemodal {
  width: 44px;
  height: 44px;
  padding: 0;
  float: right;
  margin-top: 5px;
}

.closemodal :hover {
  cursor: pointer;
}

/* tariff plan  start*/
.tariffdiv {
  background-color: #f3f6f9;
  width: 32px;
  height: 32px;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-right: 5px; */
}

.tariff-lg-img {
  width: 269px;
  height: 30%;
  margin-top: 30px;
  margin-bottom: 5%;
  border-radius: 12px;
}

.tariffimg {
  width: 40px;
  height: 40px;
  border-radius: 6px;
}

.abcd {
  color: #8950fc;
  font-weight: 500;
  font-size: 11px;
}

.exp-loading {
  height: 30vh;
}

/* tariff plan  end*/

.deletetenantcontainer {
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 526px;
  height: 233px;
  margin-top: 80px;
}

.deactivation-tenant {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: #ff4b55;
  display: flex;
  justify-content: center;
  margin: 20px;
}

.delete-question {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 30px;
  margin-top: 30px;
}

.tariffbtn {
  margin-top: 18px;
  border: none;
  border-radius: 12px;
  background-color: #8950fc;
  color: white;
  width: 70.8px;
  height: 33.8px;
}

.center {
  text-align: center;
}

/* tenant types active, paused, inactive -- start */
.tenant-active {
  color: #1bc5bd;
  font-family: Poppins;
  font-size: 11px;
  font-weight: 500;
  background-color: #c9f7f5;
  padding: 4px 14.5px;
  border-radius: 6px;
  width: 65px;
  height: 25px;
  cursor: default;
}

.tenant-paused {
  color: #ffa800;
  font-family: Poppins;
  font-size: 11px;
  font-weight: 500;
  background-color: #fff4de;
  padding: 4px 11px;
  border-radius: 6px;
  width: 65px;
  height: 25px;
  cursor: default;
}

.tenant-inactive {
  color: #f64e60;
  font-family: Poppins;
  font-size: 11px;
  font-weight: 500;
  background-color: #ffe2e5;
  padding: 4px 9px;
  border-radius: 6px;
  width: 65px;
  height: 25px;
}

.device-reserve {
  height: 26px;
  padding: 5px 14px;
  width: 74px;
  border-radius: 6px;
  background: rgba(214, 214, 224, 0.48);
  border-radius: 6px;
  color: #1b283f;
  font-family: Poppins;
  font-weight: 500;
  font-size: 11px;
}

/* tenant types active, paused, inactive -- end */

.table-vertical {
  display: inline-block;
  /* top: 10%; */
  /* -ms-transform: translateY(-30%); */
  transform: translateY(-20%);
}

.table-vertical-users {
  display: inline-block;
  /* top: 10%; */
  /* -ms-transform: translateY(-30%); */
  transform: translateY(-80%);
}

.users-table-vertical {
  display: inline-block;
  transform: translateY(-85%);
}

/* Add User Start */
.usermodalinput {
  border: 9px;
  width: 466px;
  border-radius: 9px;
}

.MuiInput-formControl {
  background-color: #f3f6f9;
}

select.usermodalinput {
  background: #f3f6f9;
  height: 35px;
}

.deactivation-input {
  border: 9px;
  width: 466px;
  height: 44px;
  background-color: #f3f6f9;
  border-radius: 9px;
}

.input-p {
  white-space: nowrap;
  font-family: Poppins;
  font-size: 14px;
  color: #464e5f;
  font-weight: 400;
}

.usercontainer {
  border-radius: 12px;
  display: flex;
  justify-content: center;
  width: 526px;
  height: 500px;
  margin-top: 70px;
}

.userform {
  border-radius: 12px;
  display: flex;
  justify-content: center;
}

/* Add User End */

/* AddUserForm start */
.temppassword {
  width: 260px;
  height: 44px;
  background-color: #f3f6f9;
  font-weight: bold;
  border-radius: 9px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.column-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* AddUserForm end */

/* form buttons start */
.form-back-button {
  background-color: #f2f4f5;
  color: black;
  width: 225px;
  height: 56px;
  font-weight: 700;
  border-radius: 6px;
  border: none;
}

.form-next-button1 {
  background-color: #8950fc;
  color: white;
  width: 466px;
  height: 56px;
  font-weight: 700;
  border-radius: 6px;
  border: none;
  font-size: 16px;
}

.form-next-button2 {
  background-color: #8950fc;
  border-radius: 6px;
  border: none;
  color: white;
  font-size: 16px;
  font-weight: 700;
  width: 225px;
  height: 56px;
}

/* form buttons start */

.reporting-search {
  /* background: url("http://kodyrabatowe.wp.pl/img/ico/search_gr.png") top left
      no-repeat;
    height: 30px;
    padding-left: 25px; */
  width: 275px;
  flex: 1;
  height: 40px;
  background-color: #f3f6f9;
}

.users-table-tenant-name {
  color: #b5b5c3;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
}

.search-month-send {
  display: flex;
  margin-left: 10px;
  align-items: center;
}

.chart-div-border {
  border-radius: 12px 12px 0px 0px;
}

@media only screen and (max-width: 767px) and (min-width: 550px) {
  .exp-details-div {
    margin-left: 100px;
  }
}

/* thead css start */

.test {
  text-align: right;
}

.lang-flag :hover {
  cursor: pointer;
}

.toggle-hover:hover {
  background-color: #8950fc !important;
}

.btn-bckg {
  background-color: transparent;
  box-shadow: none;
  margin-left: 10px;
}

.width-50 {
  width: 50px;
}

.width-25 {
  width: 25px;
}

.width-55 {
  width: 55px;
}

.width-104 {
  width: 104px;
}

.width-130 {
  width: 130px;
}

.width-120 {
  width: 120px;
}

.width-80 {
  width: 80px;
}

.width-94 {
  width: 94px;
}

.width-100 {
  width: 100px;
}

.width-100perc {
  width: 100%;
}

.width-50perc {
  width: 50%;
}

.width-158 {
  width: 158px;
}

.width-65 {
  min-width: 65px;
}

.width-200 {
  min-width: 200px;
}

.width-260 {
  width: 260px;
}

.min-width-158 {
  min-width: 158px;
}

.min-width-100 {
  min-width: 100px;
}

.width-170 {
  width: 170px;
}

.width-201 {
  width: 201px;
}

.width-125 {
  width: 125px;
}

.width-100px {
  width: 90px;
}

.width-70 {
  width: 70px;
}

.thead-border {
  border: 1px solid #f3f6f9;
  background: #ffffff;
}

.thead-p {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  text-transform: uppercase;
  color: #b5b5c3;
}

/* thead css end */

.border-none {
  border: 0 !important;
}

.nowrap {
  white-space: nowrap;
}

.poppins {
  font-family: Poppins;
  font-weight: 500;
}

.td-title {
  font-family: Poppins;
  font-size: 12px;
  font-weight: 500;
  text-align: left;
  color: #464e5fa3;
}

.tariff-btns {
  display: flex;
  align-items: center;
}

.td-text {
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  text-align: left;
  color: #212121;
}

.border-round {
  border: 1px solid #f3f6f9;
  border-radius: 6px;
}

.collapse-bckgr {
  background-color: #8950fc07;
}

/* tenant table start */
.thead-tenant {
  border: 1px solid #f3f6f9;
  border-radius: 6px;
}

/* tenant table end */

.tenant-search {
  margin: 0 25px 25px 30px;
}

.tenant-search-input {
  flex: 1;
  background-color: #f3f6f9;
}

/* Main css properties start */

.margin-top-10 {
  margin-top: 10px;
}

.margin-top-30 {
  margin-top: 30px;
}

.margin-top-50 {
  margin-top: 50px;
}

.margin-top-25 {
  margin-top: 25px !important;
}

/* Main css properties end */

.deletebtngrid {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 30px;
}

.tabletitle {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #212121;
}

.org-title {
  font-family: Poppins;
  font-size: 12px;
  font-weight: 500;
  color: #b5b5c3;
  text-align: left;
}

.tenant-title {
  font-family: Poppins;
  font-size: 28px;
  font-weight: 600;
  /* text-align: left; */
}

.tenant-add-hr {
  width: 526px;
  margin-top: 30px;
  margin-bottom: 30px;
}

.tenant-details {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 700;
  text-align: left;
  color: #3f4254;
}

.id-padding {
  padding-top: 10px;
}

.address {
  padding-top: 25px;
}

.activat {
  padding-top: 25px;
}

.t-details {
  /* display: inline; */
  display: flex;
  flex: 1;
  align-items: flex-start;
}

.tenant-info-det {
  font-family: Poppins;
  font-size: 15px;
  font-weight: 500;
  text-align: right;
  /*float: right;*/
  color: #181c32;
}

.expenditure-details-title {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  color: #181c32;
}

.expenditure-history-title {
  font-family: Poppins;
  font-size: 28px;
  font-weight: 600;
  color: #181c32;
  margin-top: 20px;
}

.expenditure-details-border {
  border-radius: 12px 12px 0px 0px;
}

/* Delete modal start */

.delete-box {
  margin-left: 200px;
  margin-bottom: 30px;
}

.delete-yes {
  width: 225px;
  height: 56px;
  background-color: #8950fc;
  color: white;
  margin-top: 30px;
  margin-bottom: 30px;
  border: none;
  border-radius: 6px;
  font-weight: 700;
}

/* Delete modal end */

.delete-no {
  width: 225px;
  height: 56px;
  background-color: #f2f4f5;
  color: white;
  margin-top: 30px;
  margin-bottom: 30px;
  border: none;
  border-radius: 6px;
  color: black;
  font-weight: 700;
}

.chart-gjh {
  width: 45px;
  max-height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-bottom: 30px;
  margin-top: 12px;
  margin-left: 15px;
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
}

.chart-menu {
  display: flex;
  justify-content: space-between;
  max-width: 280px;
  width: 100%;
  margin: auto;
  flex: 1;
}

.chart-menu-item {
  background-color: white;
  border: none;
  color: #b5b5c3;
  font-weight: 600;
  font-size: 12px;
  font-family: Poppins;
}

.chart-menu-item-selected {
  background-color: #464e5f;
  border-radius: 6px;
  color: #fff;
}

.excel {
  color: #0bb7af;
  height: 36px;
  background-color: #c9f7f5;
  margin-left: 13px;
  width: 137px;
  border-radius: 5px;
  font-size: 12px;
}

.tenant-details-border {
  margin: 30px;
  border-bottom: 1px solid rgba(229, 234, 238, 0.64);
}

.details2 {
  margin: 0 30px 36px;
}

.date {
  margin-top: 20%;
}

.margin-left-12 {
  margin-left: 12px;
}

.cursor-pointer {
  cursor: pointer;
}

.select-month {
  height: 35px;
  border: none;
  /* padding: 5px; */
  background-color: #8950fc;
  color: white;
  font-weight: 700;
  border-radius: 5px;
  text-align: center;
}

/* User Component css start */

.user-th-1 {
  margin-bottom: -10 !important;
}

.user-img {
  width: 153px;
  height: 153px;
  margin-top: 30px;
  margin-bottom: 14px;
}

.user-info-details {
  display: flex;
  flex-direction: row;
}

.first-col {
  margin-right: 30px;
  display: flex;
  flex-direction: column;
}

.second-col {
  display: flex;
  flex-direction: column;
}

.user-title {
  font-family: Poppins;
  font-size: 17px;
  font-weight: 600;
}

.user-subtitle {
  font-family: Poppins;
  font-size: 12px;
  font-weight: 500;
  color: #80808f;
}

.user-det-title {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 0;
}

.user-det-p {
  color: #b5b5c3;
  font-family: Poppins;
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 30px;
}

.user-reset-pass-btn {
  height: 56px;
  width: 260px;
  border-radius: 6px;
  padding: 16px, 80px, 16px, 80px;
  color: #3f42540a;
  border: none;
}

.user-reset-pass-text {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 700;
  color: #3f4254;
}

.edituserinput {
  border: 9px;
  background-color: #f3f6f9;
  border-radius: 9px;
  width: 260px;
  height: 44px;
}

.edit-user-form-container {
  display: flex;
  justify-content: space-around;
  padding-left: 0;
  padding-right: 30px;
  width: 596px;
}

.edit-user-container {
  margin-top: 70px;
  width: 596px;
}

.edit-user-form-back-btn {
  background-color: #f2f4f5;
  color: black;
  width: 260px;
  height: 56px;
  font-weight: 700;
  border-radius: 6px;
  border: none;
}

.edit-user-form-save-btn {
  background-color: #8950fc;
  color: white;
  width: 260px;
  height: 56px;
  font-weight: 700;
  border-radius: 6px;
  border: none;
}

/* User Component css end */

/* Organization Details start */

.org-img {
  padding: 25px;
  border-radius: 40px;
}

.org-details {
  color: #7e8299;
}

.font-14 {
  font-size: 14px;
}

.font-16 {
  font-size: 16px;
}

.height-35 {
  height: 35px;
}

/* Organization Details end */

.margin-top-30 {
  margin-top: 30px;
}

.margin-left-45 {
  margin-left: 45px;
}

/* Invoice Page start */
.create-invoice {
  border: none;
  background-color: #8950fc;
  width: 153px;
  height: 40px;
  border-radius: 6px;
  color: white;
  font-family: Poppins;
  font-weight: 600;
  font-size: 12px;
}

/* Admin Table css start */

.margin-bottom {
  margin-bottom: 30px;
}

.tenant-details-div {
  overflow-x: auto;
}

.hi-user-div {
  display: flex;
  flex-direction: column;
}

.height-300 {
  height: 300px;
  overflow-x: auto;
  overflow-y: hidden;
}

.user-dropdown-div {
  width: 35px !important;
  height: 35px !important;
}

.tenants-table {
  overflow-x: auto;
  min-width: 800px;
}

.tenants-devices-table {
  overflow-x: auto;
  min-width: 600px;
}

.tenants-users-table {
  overflow-x: auto;
  min-width: 600px;
}

.add-device-btn {
  height: 40px;
  width: 155px;
  border-radius: 6px;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 600;
  color: white;
  border: none;
  background-color: #8950fc;
}

.delete-device-btn {
  height: 40px;
  width: 155px;
  border-radius: 6px;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 600;
  color: white;
  border: none;
  background-color: rgba(255, 0, 0, 0.735);
}

/* Admin Table css end */
.main-page-logo {
  margin-top: 5%;
  margin-bottom: 5%;
}

.width-test {
  width: 165px;
}

.exp-details-img {
  margin-top: 25px;
  display: flex;
  justify-content: center;
  min-height: 200px;
  height: 100%;
}

.user-edit-btn {
  height: 56px;
  width: 260px;
  border-radius: 6px;
  border: none;
  background: #8950fc;
}

.user-edit-text {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  color: white;
  padding-top: 16px;
  padding-bottom: 16px;
}

.edit-tariff-plan {
  height: 56px;
  width: 260px;
  border-radius: 6px;
  padding: 16px, 80px, 16px, 80px;
  border: none;
  background-color: #8950fc;
  color: white;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  margin-top: 10%;
  margin-bottom: 30px;
}

.tariff-info-p {
  font-family: Poppins;
  font-size: 13px;
  font-weight: 500;
  color: #b5b5c3;
}

.edit-tariff-btn {
  height: 30px;
  width: 63px;
  border-radius: 12px;
  border: none;
  background: #f3f6f9;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 600;
  margin-left: 10px;
  margin-top: -4px;
}

.tenant-edit-tariff-select {
  margin-top: 5px;
  border: none;
  background-color: #f3f6f9;
  border-radius: 9px;
  height: 44px;
}

.edit-tenant-tariff-container {
  border-radius: 12px;
  width: 500px;
  display: flex;
  padding: 30px;
  flex-direction: column;
  background-color: white;
}

.downl-invc {
  display: flex;
  justify-content: center;
}

.send-inv-svg {
  cursor: pointer;
  width: 110px;
  margin-left: 3px;
}

.send-inv-svg svg {
  height: 36px;
}

.auth-qwerty-logo {
  width: 182px;
  height: 96px;
}

.org-devices-table-border-none {
  border-left: 4px solid transparent;
  border-spacing: 10px !important;
}

.org-devices-table {
  background-color: #8950fc06;
  border-left: 4px solid #8950fc;
  border-spacing: 10px !important;
}

.tenant-icon-div {
  width: 44px;
  height: 44px;
}

.icon-border {
  border: 1px solid #e8e8e8;
  border-radius: 6px;
  padding: 4px;
}

.tenant-icon {
  width: 100%;
  height: 100%;
  border-radius: 6px;
}

.org-devices-collapse-table {
  background-color: #8950fc06;
  margin-top: 0 !important;
}

.org-devices-border {
  border: 1px solid rgba(70, 78, 95, 0.12);
  box-sizing: border-box;
  border-radius: 12px !important;
}

.css-10hburv-MuiTypography-root {
  color: rgba(0, 0, 0, 0.6);
}

.qwerty-loading-img {
  max-width: 400px;
  margin-bottom: 50px;
}

img {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}

.text-center {
  text-align: center !important;
}

.sale {
  padding-left: 30px;
}

@media (max-width: 768px) {
  .exp-scroll {
    overflow-x: scroll;
  }
}
