.reportingContainer {
  .DateRangePickerInput_arrow_svg,
  .DateRangePickerInput_arrow_svg_1 {
    display: none;
  }

  .DateInput_input,
  .DateInput_input_1 {
    background: rgba(239, 99, 39, 0.12);
    color: #464e5f;
    font-family: Poppins;
    font-size: 10px;
    font-weight: 500;
    height: 39px;
    padding: 5px;
  }

  .CalendarDay__selected_span {
    background: rgba(239, 99, 39, 0.12);
    color: #ef6327;
  }

  .CalendarDay__selected {
    color: #ef6327;
    background: rgba(239, 99, 39, 0.12);
    border-radius: 6px;
  }

  .CalendarDay__selected:hover {
    background: orange;
    color: white;
  }

  .CalendarDay__hovered_span:hover,
  .CalendarDay__hovered_span {
    background: rgb(177, 174, 174);
  }

  .calendar-icon {
    position: absolute;
    top: 12px;
    right: 12px;
  }

  .DateInput {
    width: 70px;
  }

  .DateRangePickerInput__withBorder,
  .DateRangePickerInput__withBorder_2 {
    border-radius: 6px;
    width: 180px;
    padding-left: 6px;
    background-color: rgba(239, 99, 39, 0.12);
    border: 1px solid rgba(239, 99, 39, 0.021);
    margin-left: 5px;
    overflow: hidden !important;
  }

  .reportingbtn {
    float: right;
    width: 86px;
    height: 25px;
    color: #0bb7af;
    background-color: #e25353;
    border: none;
    font-weight: 500;
    font-size: 11px;
    font-family: Poppins;
    border-radius: 6px;
  }

  .down-reporting {
    cursor: pointer;
    width: 110px;
    margin-left: 15px;
  }

  .search-month-download-reporting {
    margin-top: 5px;
  }

  .reporting-table {
    overflow-x: auto;
    min-width: 1000px;
  }

  @media only screen and (max-width: 600px) {
    .search-month-send {
      flex: 1;
      justify-content: space-between;
      margin-left: 0;
    }

    .DateRangePickerInput__withBorder,
    .DateRangePickerInput__withBorder_2 {
      margin-left: 0 !important;
      width: 170px !important;
    }

    .search-month-download-reporting {
      margin-top: 16px;
      justify-content: space-between;
      margin-left: 0;
    }

    .down-reporting {
      margin-left: 12px !important;
    }
  }
}
